<template>
<!--  <div>-->
<!--    <span class='subtitle-1'>{{header}}:</span>-->
<!--    <span class='body-1'>{{item}}</span>-->
<!--  </div>-->
  <v-card class='elevation-3'>
    <v-card-text>
      <span class='text-h6'>{{header}}: &nbsp;&nbsp;</span>
      <span class='text-h6' style='font-weight: normal'> {{item}} </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['header', 'item']
};
</script>
