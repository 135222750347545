<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class='headline green--text font-weight-bold text--darken-4'>
          Patients Registered:
        </div>
        <hr class='green'/>
        <br/>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm='2'>
        <StatComp :header='month' :item='dash.MonthlyPatientCount'></StatComp>
      </v-col>
      <v-col sm='2'>
        <StatComp :header='year' :item='dash.YearlyPatientCount'></StatComp>
      </v-col>
      <v-col sm='2'>
        <StatComp header='Total' :item='dash.OverallPatientCount'></StatComp>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <br/>
        <br/>
        <div class='headline green--text font-weight-bold text--darken-4'>
          Appointments
        </div>
        <hr class='green'/>
        <br/>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm='2'>
        <StatComp header='Today' :item='dash.TodaysAppointment'></StatComp>
      </v-col>
      <v-col sm='2'>
        <StatComp header='Tomorrow' :item='dash.TomorrosAppointment'></StatComp>
      </v-col>
      <v-col sm='2'>
        <StatComp
          header='Next 7 Days'
          :item='dash.WeeklyAppointment'
        ></StatComp>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import WebH from '../api/web';
import StatComp from '../components/dashboard/Stat';

export default {
  components: {
    StatComp
  },
  data() {
    return {
      dash: {},
      month: '',
      year: ''
    };
  },
  created() {
    const d = new Date();
    //this.month = d.getMonth();
    this.month = d.toLocaleString('en-us', { month: 'short' });
    this.year = d.getFullYear();
    this.api_get();
  },
  methods: {
    async api_get() {
      const res = await WebH.Get('/dashboard', 'dashboard');
      if (res.Success) {
        this.dash = res.Data;
      }
    }
  }
};
</script>
